:root,
:host {
  /**
   * @tokens depth
   * @presenter Shadow
   */
  --depth-1: 0 2px 2px rgba(10, 2, 22, 0.2);
  --depth-2: 0 4px 6px rgba(10, 2, 22, 0.2);
  --depth-3: 0 6px 8px rgba(10, 2, 22, 0.2);
  --depth-4: 0 0 11px rgba(10, 2, 22, 0.1), 0 14px 18px rgba(10, 2, 22, 0.2);
}
